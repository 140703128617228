<template>
  <index-content>
    <template #options>
      <n-refresh-info :response="response" />
    </template>
    <div class="table-responsive">
      <table class="table table-bordered table-hover table-striped text-center n-table">
        <thead>
          <th class="id">{{ $t('label.id') }}</th>
          <th class="name">{{ $t('label.countryName') }}</th>
          <th class="code">{{ $t('label.countryCode') }}</th>
          <th class="phone">{{ $t('label.phoneCode') }}</th>
          <th class="language">{{ $t('label.language') }}</th>
          <th class="currency">{{ $t('label.currency') }}</th>
          <th class="nationality">{{ $t('label.nationality') }}</th>
          <th v-table-action class="action-edit">{{ $t('label.action') }}</th>
        </thead>
        <n-draggable v-model="records" :disabled="!sortable" @on-change="onDropped">
          <tr v-for="row in records" :key="row.id" :class="{ 'n-table-sortable': sortable }">
            <td>{{ row.id }}</td>
            <td>{{ row.countryName }}</td>
            <td>{{ row.countryCode }}</td>
            <td>{{ row.phoneCode }}</td>
            <td v-enums:Language="row.defaultLanguage"></td>
            <td v-selection:[`market.currency`]="row.defaultCurrency"></td>
            <td>{{ row.nationality }}</td>
            <td class="n-button-box">
              <nb-modal @on-click="onBtnShowModal(row)"></nb-modal>
            </td>
          </tr>
        </n-draggable>
      </table>
    </div>
  </index-content>
</template>

<script>
import BaseMarketView from './view';
import DndMixin from '@/mixins/dnd-mixin';
import { ImageType } from '@/constant/enums';
import ModalCountry from './ModalCountry';
import { fetch, sort } from '@/api/market/market-country';

export default BaseMarketView.extend({
  name: 'MarketCountry',
  mixins: [DndMixin],

  data() {
    return {
      ImageType,
      preload: true,
      sortable: false,
    };
  },

  computed: {},

  methods: {
    doLoad() {
      return fetch();
    },

    parse(r) {
      this.records = r;
    },

    onBtnShowModal(row) {
      this.createModal(ModalCountry, { model: row, on: this });
    },

    doDrop(list) {
      return sort({ countryIds: list.map(r => r.id).join(',') });
    },
  },
});
</script>
