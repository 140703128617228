import { $fetch, $put, $post } from '@/services/axios';

export function fetch() {
  return $fetch(`market/countries`);
}

export function add(data) {
  return $put(`market/country`, data);
}

export function sort(data) {
  return $post(`market/countries/sort`, data);
}

export function validate(data) {
  return $post(`market/country/validate`, data);
}

export function edit(data) {
  return $post(`market/country/${data.id}`, data);
}
