<template>
  <n-modal v-bind="$props">
    <Form ref="modalForm" :model="model" :label-width="labelWidth" class="n-modal-container" @submit.native.prevent>
      <n-row-col2>
        <!-- id -->
        <!-- prettier-ignore -->
        <n-field-id v-model="model.id" field-name="id" label="id" :error="error.id" :disabled="isEdit || disabled" />
        <!-- countryCode -->
        <!-- prettier-ignore -->
        <n-field-text v-model="model.countryCode" field-name="countryCode" label="countryCode" :error="error.countryCode" :disabled="disabled" />
      </n-row-col2>
      <n-row-col2>
        <!-- countryName -->
        <n-field-text v-model="model.countryName" field-name="countryName" label="countryName" :error="error.countryName" :disabled="disabled" />
        <!-- nationality -->
        <n-field-text v-model="model.nationality" field-name="nationality" label="nationality" :error="error.nationality" :disabled="disabled" />
      </n-row-col2>
      <n-row-col2>
        <!-- phoneCode -->
        <!-- prettier-ignore -->
        <n-field-text v-model="model.phoneCode" field-name="phoneCode" label="phoneCode" :error="error.phoneCode" :disabled="disabled" />
        <!-- defaultLanguage -->
        <!-- prettier-ignore -->
        <n-field-enum v-model="model.defaultLanguage" field-name="defaultLanguage" enum-name="Language" :error="error.defaultLanguage" label="language" :disabled="disabled" />
      </n-row-col2>
      <n-row-col2>
        <!-- version -->
        <n-field-id v-model="model.version" label="version" disabled />
        <!-- defaultCurrency -->
        <!-- prettier-ignore -->
        <n-field-selection v-model="model.defaultCurrency" field-name="defaultCurrency" selection-name="market.currency" :error="error.defaultCurrency" label="currency" :disabled="disabled" />
      </n-row-col2>
    </Form>
  </n-modal>
</template>
<script>
import NModal from '@/components/common/NModal';
import { validate, edit } from '@/api/market/market-country';

export default NModal.extend({
  name: 'ModalCountry',
  components: { NModal },

  methods: {
    doSubmit(model) {
      return edit(model);
    },

    doValidate(model) {
      return validate(model);
    },
  },
});
</script>
